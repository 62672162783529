@import "scss/mixins";
@import "scss/ag-grid";
@import "scss/library-overwrite";


html {
  font-size: 14px;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', sans-serif,
  monospace;
}


.ant-select-dropdown {
  z-index: 5000;
}

.ant-dropdown {
  z-index: 7500;
}


.ant-collapse-content {
    overflow: visible;
}

.ant-collapse-content {
    overflow: visible;
}

.ant-layout {
  background: #F1F3F6;
  position: relative;
}

.ant-layout-header {
  position: absolute;
  z-index: 3;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  //background: none !important;
  height: 52px;
  line-height: 0;
  padding: 0;
  background: #F1F3F6;
  margin: 20px 20px 20px;
}


.hidden {
  display: none !important;
}

.styledWrapper{
  margin: 0 20px;
  width: calc(100% / 12 * 4 - 40px);
  min-width: 250px;
  justify-content: flex-start;
  position: relative;
  & .ant-form-item {
    width: 100%;
  }
}
.workInProgress {
  display: none !important;
  //background-image: repeating-linear-gradient(-45deg,
  //        rgba(0, 0, 0, 0.2),
  //        rgba(0, 0, 0, 0.2) 20px,
  //        rgba(249, 166, 2, 0.2) 20px,
  //        rgba(249, 166, 2, 0.2) 40px);
}

.ant-select-dropdown-menu {
  max-height: 166px;
  border-radius: 0px 0px 8px 8px;

}

.ant-select.ant-select-open.ant-select-enabled.ant-select-focused{
  border-radius: 8px 8px 0px 0px;

}

.ant-select-dropdown.ant-select-dropdown--single {
  width: inherit;
  font-family: AvantGardeGothicC, Serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 140%;
  border-radius: 8px;
  box-shadow: 0px 2px 8px rgba(0, 130, 145, 0.08);
  background: #F1F3F6;

  ::-webkit-scrollbar{
    width: 6px;
    color: #880000;
    background: #F1F3F6;
  }
  ::-webkit-scrollbar-track {
    border: none;
    width: 10px;
    border-radius: 8px;
  }
  ::-webkit-scrollbar-thumb{
    background: #C1CBD8;
    border-radius: 8px;
  }
}




//temp

.layers-wrapper {
  position: absolute;
  left: 20px;
  bottom: 20px;
  top:90px;
  width: fit-content;
  z-index: 1;

}

.mapTool-wrapper{
  position: absolute;
  right: 20px;
  top: 90px;
  height: 90%;
  width: 52px;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.vehicles-wrapper{
  margin-top: 100px;
}

.gridTable-wrapper{
  padding-top: 80px;
}

.mapSelect-wrapper{
  padding-top: 100px;
}

.timePlayer-wrapper{
  top: 90px;
}

.objectPanel-wrapper{
  margin-top: 80px;
}

.groupPanel-wrapper{
  margin-top: 80px;
}
.disable{
  background-color: rgba(0,0,0,0.4);
}
